<template>
    <!--政策文章的详情页面-->
    <div class="container">
        <div class="article-box">
            <p class="article-content" v-html="articleContent"></p>


        </div>
    </div>
</template>

<script>
    export default {
        name: "articleDetails",
        mounted(){

        },
        data(){
            return{
                articleContent:`
                <span style="text-align: center; color: #00c2b3;">
                标题1111
            </span>
            <div>内容111</div>
`
            }
        },
        methods:{

        }
    }
</script>

<style lang="scss" scoped>
    .container{
        width: 60%;
        margin:120px auto 60px auto;
        background-color: #FFFFFF;

    }

    .article-box{
        padding: 50px;
    }
</style>